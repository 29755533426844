import React from 'react';
import { connect } from "react-redux";

import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import { updateTip } from 'redux/actions/cart/actions';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
  Box,
  FormHelperText,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minHeight: 'fit-content',
    width: '100%',
  },
  freeDelivery: {
    backgroundColor: '#d9534f',
    color: 'black',
    padding: 10,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRadius: 5,
    textAlign: 'center',
  },
}));

const Tip = ({ dispatch, tipAmount, className, user, ...rest }) => {
  const classes = useStyles();

  const handleTipChange = (tip) => {
    dispatch(updateTip(Number(tip.toFixed(2))));
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <FormHelperText className={classes.freeDelivery}>
        <b>FREE DELIVERY</b>
      </FormHelperText>
      <Box p={1} mt={1} ml={1} mr={1} bgcolor="background.paper" >
        <CurrencyTextField
          fullWidth
          label="TIP"
          id="tip"
          variant="outlined"
          size="small"
          value={tipAmount}
          currencySymbol="$"
          minimumValue="0"
          //outputFormat="string"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          onChange={(event, tip)=> handleTipChange(tip)}
          />
      </Box>
    </div>
  );
};

Tip.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = ({ cart }) => ({
  tipAmount: cart.tipAmount,
});

export default connect(
  mapStateToProps,
)(Tip);

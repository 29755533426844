import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';

import { connect } from "react-redux";
import { logoutUser } from "../../../../redux/actions";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  toolbar: {
    backgroundColor: '#d9534f'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = ({ dispatch, isLoggingOut, logoutError, cartTotal, onSidebarOpen, onDrawerCartOpen, onDrawerCartToggle, className, ...rest }) => {
  const classes = useStyles();

  const [notifications] = useState([]);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <img
            alt="Logo"
            style={{ borderRadius: 5 }}
            src="./images/logos/logo.png"
            height="36"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <IconButton color="inherit">
          <Badge
            badgeContent={notifications.length}
            color="primary"
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <InputIcon />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={onDrawerCartToggle}
        >
          <Badge color="secondary" badgeContent={cartTotal.productQuantity} showZero>
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  onDrawerCartOpen: PropTypes.func,
  onDrawerCartToggle: PropTypes.func
};

function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError,
    cartTotal: state.total.data,
  };
}

export default connect(mapStateToProps)(Topbar);
